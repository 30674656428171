import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { WPublicWeddingView } from '@zola/svc-web-api-ts-client';

type PublicV2MetadataProps = {
  wedding: WPublicWeddingView;
};

const PublicV2Metadata = ({ wedding }: PublicV2MetadataProps): JSX.Element => {
  const router = useRouter();
  const { preview_type } = router?.query || {};
  const viewportContent =
    (preview_type as string | undefined)?.toUpperCase() === 'DESKTOP'
      ? 'width=1200'
      : 'width=device-width, initial-scale=1.0';
  return (
    <Head>
      <title>{`${wedding.owner_first_name} ${wedding.owner_last_name} and ${wedding.partner_first_name} ${wedding.partner_last_name}'s Wedding Website`}</title>
      <meta
        name="description"
        content={`The wedding website of ${wedding.owner_first_name} ${wedding.owner_last_name} and ${wedding.partner_first_name} ${wedding.partner_last_name}`}
      />
      <meta
        name="keywords"
        content={`wedding website, ${wedding.owner_first_name} ${wedding.owner_last_name}, ${wedding.partner_first_name} ${wedding.partner_last_name}, ${wedding.owner_first_name} and ${wedding.partner_first_name}`}
      />
      <meta name="viewport" content={viewportContent} />
      {!wedding.enable_search_engine && <meta name="robots" content="noindex" />}
    </Head>
  );
};

export default PublicV2Metadata;
